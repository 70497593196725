body, html {
  margin: 0;
}

body {
  background-color: #50371f;
  background-position: left bottom, right bottom;
  background-repeat: no-repeat, no-repeat;
}

.Cargando {
  height: 100vh;
}

.Cargando-logo {
  position: absolute;
  top: 40%;
}

.Error {
  text-align: center;
  color: red;
}

.Login {
  height: 100vh;
}

.Login-card > h2 {
  color: #50371f;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.Login-card {
  background-color: #dbc2a9;
  padding: 20px;
}

.Login-card > form > .form-group {
  margin-bottom: 10px;
}

.Login-card > form {
  text-align: center;
}

.btn-iniciar-sesion {
  background-color: #50371f;
  color: #dbc2a9;
  border-color: unset;
}

.btn-iniciar-sesion:hover {
  background-color: #67431d;
  color: #dbc2a9;
  border-color: unset;
}

.Admin-panel {
  height: 100vh;
}

.Admin-panel > nav {
  background-color: #dbc2a9;
  padding: 10px;
}

.navbar-brand {
  pointer-events: none;
  color: #50371f;
  font-weight: bolder;
}

.nav-link {
  color: #50371f;
}

.Admin-card {
  padding: 15px;
  margin: 15px 0 15px 0;
  border-radius: 0.25rem;
  background-color: antiquewhite;
}

.Admin-card > h2 {
  color: #50371f;
}

.Admin-card > form {
  padding: 5%;
}

.Section {
  margin-bottom: 10px;
}

.Frase > p {
  margin-bottom: 1px;
  color: #dbc2a9;
}

.Datos-personales {
  font-size: 14px;
  font-weight: 500;
  padding: 8% 15% 1% 15%;
}

.Datos-personales > p {
  color: #dbc2a9;
  margin-bottom: 1px;
}

.Datos-personales > .Nombre {
  font-size: 34px;
  font-weight: bold;
}

.Datos-personales > .Titulo {
  font-size: 20px;
  font-weight: bold;
}

.Datos-personales > .Matricula {
  font-size: 18px;
  font-weight: bold;
}

.Frase {
  font-size: 14px;
  font-weight: 500;
  padding: 8% 15% 1% 15%;
  text-align: center;
  font-style: italic;
}

.Frase-autor {
  color: #dbc2a9;
  font-size: 12px;
  font-weight: 900;
  text-align: center;
}

.Menu-item {
  text-align: center;
}

.Menu-item > a {
  font-family: Copperplate, Papyrus, fantasy;
  color: black;
}

.About {
  margin-top: 2%;
  text-align: center;
  color: #dbc2a9;
}

.About > p {
  font-size: 16px;
}

.Contact {
  margin-top: 2%;
  text-align: center;
  color: #dbc2a9;
}

.Contact > form {
  color: #dbc2a9;
}

.Contact > form > .form-group {
  margin-bottom: 5px;
}

.Social-link {
  color: #dbc2a9;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  body {
    background-image: url(./img/fondo_mobile_izq.png), url(./img/fondo_mobile_der.png);
  }

  .Cargando-logo {
    left: 40%;
  }

  .Login-card {
    margin: 5% 5% 20% 5%;
  }

  .Header {
    margin-right: 0;
  }

  .Datos-personales-col {
    padding-right: 0;
  }

  .Datos-personales > p {
    text-align: center;
  }

  .Frase-col {
    padding-right: 0;
  }

  .Frase-container {
    padding-right: 0;
    margin-right: 0;
    --bs-gutter-x: 0;
  }

  .About {
    padding: 0 20px 0 20px;
  }

  .Contact {
    padding: 0 20px 0 20px;
  }

  .Contact > form {
    padding: 0 10px 0 10px;
  }

  .Social-contact > p {
    margin-bottom: 5px;
  }
}

/* Media Query for low resolution  Tablets, Ipads
@media (min-width: 481px) and (max-width: 767px) {
  body {
    background-color: yellow;
  }
}

/* Media Query for Tablets Ipads portrait mode
@media (min-width: 768px) and (max-width: 1024px){
  body {
    background-color: blue;
  }
}

/* Media Query for Laptops and Desktops
@media (min-width: 1025px) and (max-width: 1280px){
  body {
    background-color: green;
  }
}

/* Media Query for Large screens
@media (min-width: 1281px) { */

@media (min-width: 481px) {
  body {
    background-image: url(./img/fondo_izq.png), url(./img/fondo_der.png);
  }

  .Cargando-logo {
    left: 46%;
  }

  .About {
    padding: 0 20% 0 20%;
  }

  .Contact {
    padding: 0 20% 0 20%;
  }

  .Contact > form {
    padding: 0 10% 0 10%;
  }

  .Instagram-link {
    text-align: right;
  }

  .Whatsapp-link {
    text-align: left;
  }
}